<template>
  <div class="advanced-query">
    <iframe
      :src="src"
      width="1200"
      style="min-height: 1100px; background-color: #fff;"
      ref="iframe"
    ></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      src: ""
    };
  },
  mounted() {
    const loading = this.$loading({
      lock: true,
      text: "加载中...",
      spinner: "el-icon-loading",
      background: "rgba(255, 255, 255, 0.3)",
      fullscreen: false
    });
    this.$api.successful
      .getUrl()
      .then(res => {
        this.src = res.data;
        this.loading = true;
        const iframe = this.$refs.iframe;
        // 兼容处理
        if (iframe.attachEvent) {
          // IE
          iframe.attachEvent("onload", () => {
            loading.close();
          });
        } else {
          // 非IE
          iframe.onload = () => {
            loading.close();
          };
        }
      })
      .catch(msg => {
        if (msg?.msg) {
          this.$message.error(msg?.msg);
          loading.close();
        }
      });
  },
};
</script>

<style lang="less" scoped>
.advanced-query {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 15px;
  box-sizing: border-box;
}
</style>
